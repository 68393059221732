/**
 * This file is part of VILLASweb.
 *
 * VILLASweb is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * VILLASweb is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with VILLASweb. If not, see <http://www.gnu.org/licenses/>.
 ******************************************************************************/

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { useGetScenarioByIdQuery } from "../../store/apiSlice";
import IconButton from "../../common/buttons/icon-button";
import IconToggleButton from "../../common/buttons/icon-toggle-button";
import ConfigsTable from "./tables/configs-table";
import DashboardsTable from "./tables/dashboards-table";
import ResultsTable from "./tables/results-table";
import EditFilesDialog from '../dashboards/dialogs/edit-files-dialog'
import UsersTable from "./tables/users-table";
import { useEffect, useState } from "react";
import { 
  useGetDashboardQuery, 
  useLazyGetWidgetsQuery, 
  useLazyGetConfigsQuery, 
  useAddWidgetMutation, 
  useUpdateWidgetMutation, 
  useDeleteWidgetMutation,
  useUpdateScenarioMutation,
  useLazyGetFilesQuery,
  useUpdateDashboardMutation,
  useGetICSQuery,
  useLazyGetSignalsQuery,
  useDeleteFileMutation,
  useAddFileMutation
} from '../../store/apiSlice';

const Scenario = (props) => {
    const params = useParams();
    const id = params.scenario;
      const [triggerGetFiles] = useLazyGetFilesQuery();
    const [triggerDeleteFile] = useDeleteFileMutation();
    const [triggerUploadFile] = useAddFileMutation();
    const { user: currentUser, token: sessionToken } = useSelector((state) => state.auth);
    
    const [filesEditModal, setFilesEditModal] = useState(false);
    const { data: fetchedScenarios, isLoading: isScenarioLoading, refetch: refetchScenario } = useGetScenarioByIdQuery(id);
    const scenario = fetchedScenarios?.scenario;

    const { data: fetchedICs, isLoading: areICsLoading, error, refetch: refetchICs } = useGetICSQuery(id);
    const ics = fetchedICs?.ics;

    const [updateScenario, {isLoadingUpdate}] = useUpdateScenarioMutation();
    const [files, setFiles] = useState([]);
    const [locked, setLocked] = useState(false);

    const buttonStyle = {
        marginLeft: '10px',
    }
  
    const iconStyle = {
        height: '30px',
        width: '30px'
    }

    useEffect(async ()=>{
      
      await triggerGetFiles(id)
        .then((fs)=>{
            setFiles(fs.data.files)
        })
      
    },[])

    const uploadFile = async (file) =>{
      await triggerUploadFile({scenarioID:id, file:file})
      .then(async ()=>{
        await triggerGetFiles(id)
          .then((fs)=>{
              setFiles(fs.data.files)
          })
      })
      .catch((e)=>{
        console.log(`File upload failed: ${e}`)
      })
    }
  
    const deleteFile = async (index) =>{
      let file = files[index]
      if(file !== undefined){
        await triggerDeleteFile(file.id)
        .then(async ()=>{
          await triggerGetFiles(id)
          .then((fs)=>{
              setFiles(fs.data.files)
          })
          .catch((e)=>{
            console.log(`Error fetching files: ${e}`)
          })
        })
        .catch((e)=>{
          console.log(`Error deleting: ${e}`)
        })
       
      }
      
    }
  
    const closeEditFiles = () => {
      setFilesEditModal(false);
    };

    const onScenarioLock = async (index) => {
      try{
        const data = {...scenario};
        data.isLocked = !data.isLocked;
        await updateScenario({id: scenario.id, ...{scenario: data}}).unwrap();
        refetchScenario();
      } catch(error){
        console.log('Error locking/unlocking scenario', error)
      }
    }

    if(isScenarioLoading){
      return <div>Loading...</div>
    } else {
      const tooltip = scenario.isLocked ? "View files of scenario" : "Add, edit or delete files of scenario";

      return (
        <div className='section'>
        <div className='section-buttons-group-right'>
          <IconButton
            childKey="0"
            tooltip={tooltip}
            onClick={() => {setFilesEditModal(true)}}
            icon="file"
            buttonStyle={buttonStyle}
            iconStyle={iconStyle}
          />
        </div>
        <h1>
          {scenario.name}
          <span className='icon-button'>
                <IconToggleButton
                  childKey={0}
                  index={scenario.id}
                  onChange={() => onScenarioLock()}
                  checked={scenario.isLocked}
                  checkedIcon='lock'
                  uncheckedIcon='lock-open'
                  tooltipChecked='Scenario is locked, cannot be edited'
                  tooltipUnchecked='Scenario is unlocked, can be edited'
                  disabled={currentUser.role !== "Admin"}
                  buttonStyle={buttonStyle}
                  iconStyle={iconStyle}
                />
              </span>
          </h1>
  
          <EditFilesDialog
          key={"edit-files-dialog"}
          sessionToken={sessionToken}
          show={filesEditModal}
          uploadFile={uploadFile}
          deleteFile={deleteFile}
          onClose={closeEditFiles}
          files={files}
          scenarioID={id}
          locked={locked}
        /> 
  
        { areICsLoading?
          <div>isLoading...</div>
          :
            <ConfigsTable
              files={files}
              ics={ics}
              scenario={scenario}
            />
        }
  
        <DashboardsTable
          scenario={scenario}
        />
  
        <ResultsTable
          scenario={scenario}
        />
  
        <UsersTable
          scenario={scenario}
        />
  
      </div>
      );
    }
}

export default Scenario;
